import * as React from 'react';
import Layout from '../components/Layout';
import { Content } from '../components/templates/PostTemplate';


const ExamplePage = () => (
  <Layout>
    <Content>
      <h1>見出し1</h1>
      <h2>見出し2</h2>
      <h3>見出し3</h3>
      <h4>見出し4</h4>
      <h5>見出し5</h5>
      <h6>見出し6</h6>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Content>
  </Layout>
)

export default ExamplePage;
